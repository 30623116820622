<script lang="ts">
type ErrorContext = {
  highlightError: Ref<boolean>;
  triggerErrorsHighlight: () => void;
};

export const [injectErrorContextInternal, provideErrorContext] =
  createContext<ErrorContext>('ErrorContext');
export const injectErrorContext = () =>
  injectErrorContextInternal({ highlightError: toRef(false), triggerErrorsHighlight: () => {} });
</script>

<script setup lang="ts">
const highlightError = ref(false);
const firstTimeSeeing = ref(true);

function triggerErrorsHighlight() {
  if (firstTimeSeeing.value || highlightError.value) {
    if (firstTimeSeeing.value) {
      firstTimeSeeing.value = false;
    }

    return;
  }

  highlightError.value = true;
  setTimeout(() => {
    highlightError.value = false;
  }, 1_000);
}

provideErrorContext({
  highlightError,
  triggerErrorsHighlight,
});

onUnmounted(() => {
  highlightError.value = false;
});
</script>

<template>
  <slot />
</template>
